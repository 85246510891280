import { format } from 'date-fns';

export interface CustomOptions {
  [key: string]: { value: string; label: string }[];
}
export interface FieldConfig {
  key: string;
  type: 'select' | 'text' | 'date';  // Add other types as needed
  label: string;
  options?: { value: string | number; label: string }[];
  placeholder?: string;
  validation: {
    required: boolean;
    minLength?: number;
  };
  visibility: boolean;
  defaultValue?: any;
}



export interface FieldConfig {
  key: string;
  type: 'select' | 'text' | 'date';
  label: string;
  options?: { value: string | number; label: string}[];
  placeholder?: string;
  validation: {
    required: boolean;
    minLength?: number;
  };
  visibility: boolean;
  defaultValue?: any;
}

export function getCommonListFormFields(
  type: string,
  customOptions: CustomOptions = {},
  defaultValues?: Record<string, any> 
): FieldConfig[] {
  const sportOptions = [
    { value: '', label: 'All' },
    { value: '1', label: 'Football' },
    { value: '2', label: 'Tennis' },
    { value: '4', label: 'Cricket' },
    { value: '-1', label: 'Live Game' },
  ];
  const marketOptions = [
    { value: '0', label: 'Match Odds' },
    { value: '1', label: 'Fancy' },

  ];

  const today = format(new Date(), 'yyyy-MM-dd');

  switch (type) {
    case 'market':
      return [
        {
          key: 'sport_id',
          type: 'select',
          label: 'Sport',
          options: [{label:'All',value:''}, ...customOptions.sport] || sportOptions,
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.sport_id || ''
        },
        {
          key: 'series_id',
          type: 'select',
          label: 'Series',
          options: customOptions.series || [],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.series_id || ''
        },
        {
          key: 'match_id',
          type: 'select',
          label: 'Match',
          options: customOptions.match || [],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.match_id || ''
        },
        {
          key: 'match_name',
          type: 'text',
          label: 'Match Name',
          placeholder: 'Enter match name',
          validation: { required: false, minLength: 3 },
          visibility: true,
          defaultValue: defaultValues?.match_name || ''
        },
        {
          key: 'is_active',
          type: 'select',
          label: 'Status',
          options: [
            { value: '', label: 'Select' },
            { value: 'true', label: 'Active' },
            { value: 'false', label: 'Inactive' },
          ],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.is_active || ''
        },
        {
          key: 'market_type',
          type: 'select',
          label: 'Market Type',
          options: customOptions.marketType || marketOptions,
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.market_type || ''
        },
      ];

    case 'match':
      return [
        {
          key: 'sport_id',
          type: 'select',
          label: 'Sport',
          options: [{label:'All',value:''}, ...customOptions.sport] || sportOptions,
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.sport_id || ''
        },
        {
          key: 'series_id',
          type: 'select',
          label: 'Series',
          options: customOptions.series || [],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.series_id || ''
        },
        {
          key: 'match_name',
          type: 'text',
          label: 'Match Name',
          placeholder: 'Enter match name',
          validation: { required: false, minLength: 3 },
          visibility: true,
          defaultValue: defaultValues?.match_name || ''
        },
        {
          key: 'is_active',
          type: 'select',
          label: 'Status',
          options: [
            { value: '', label: 'Select' },
            { value: '1', label: 'Active' },
            { value: '2', label: 'In Active' },
          ],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.is_active || ''
        },
        // {
        //   key: 'market_type',
        //   type: 'select',
        //   label: 'Market Type',
        //   options: customOptions.marketType || marketOptions,
        //   validation: { required: false },
        //   visibility: true,
        //   defaultValue: defaultValues.market_type
        // },
      ];

    case 'report-bet-history':
      return [
        {
          key: 'sport_id',
          type: 'select',
          label: 'Sport',
          options: [{label:'All',value:''}, ...customOptions.sport] || sportOptions,
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.sport_id || ''
        },
        {
          key: 'series_id',
          type: 'select',
          label: 'Series',
          options: customOptions.series || [],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.series_id || ''
        },
        {
          key: 'match_id',
          type: 'select',
          label: 'Match',
          options: customOptions.match || [],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.match_id || ''
        },
        {
          key: 'match_name',
          type: 'text',
          label: 'Match Name',
          placeholder: 'Enter match name',
          validation: { required: false, minLength: 3 },
          visibility: true,
          defaultValue: defaultValues?.match_name || ''
        },
        {
          key: 'is_active',
          type: 'select',
          label: 'Status',
          options: [
            { value: '', label: 'Select' },
            { value: 'true', label: 'Active' },
            { value: 'false', label: 'Inactive' },
          ],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.is_active || ''
        },
        {
          key: 'market_type',
          type: 'select',
          label: 'Market Type',
          options: customOptions.marketType || marketOptions,
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.market_type || ''
        },
        {
          key: 'user_name',
          type: 'text',
          label: 'User Name',
          placeholder: 'Enter user name',
          validation: { required: false, minLength: 2 },
          visibility: true,
          defaultValue: defaultValues?.user_name || ''
        },
        {
          key: 'start_date',
          type: 'date',
          label: 'Start Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.date_range || ''
        },
        {
          key: 'end_date',
          type: 'date',
          label: 'End Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.date_range || ''
        },
      ];
      //Series List
    case 'series':
      return [
        {
          key: 'sport_id',
          type: 'select',
          label: 'Sport',
          options: [{label:'All',value:''}, ...customOptions.sport] || sportOptions,
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.sport_id || ''
        },
        {
          key: 'series_name',
          type: 'text',
          label: 'Series Name',
          placeholder: 'Enter series name',
          validation: { required: false, minLength: 3 },
          visibility: true,
          defaultValue: defaultValues?.series_name || ''
        },
        {
          key: 'is_active',
          type: 'select',
          label: 'Status',
          options: [
            { value: '', label: 'Select' },
            { value: 'true', label: 'Active' },
            { value: 'false', label: 'Inactive' },
          ],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.is_active || ''
        },
        {
          key: 'start_date',
          type: 'date',
          label: 'Start Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.date_range || ''
        },
        {
          key: 'end_date',
          type: 'date',
          label: 'End Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.date_range || ''
        },
      ];
    case 'report-pl-sport-wise':
      return [
        {
          key: 'user_name',
          type: 'text',
          label: 'User',
          placeholder: 'Search for a user',
          validation: { required: false, minLength: 2 },
          visibility: true,
          defaultValue: defaultValues?.user_name || ''
        },
        {
          key: 'sport_id',
          type: 'select',
          label: 'Sports',
          options: [{label:'All',value:''}, ...customOptions.sport] || sportOptions,
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.sport_id || ''
        },
        {
          key: 'start_date',
          type: 'date',
          label: 'Start Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.start_date || ''
        },
        {
          key: 'end_date',
          type: 'date',
          label: 'End Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.end_date || ''
        },
      ];

    case 'report-pl-user-wise':
      return [
        {
          key: 'user_name',
          type: 'text',
          label: 'User',
          placeholder: 'Search for a user',
          validation: { required: false, minLength: 2 },
          visibility: true,
          defaultValue: defaultValues?.user_name || ''
        },
        {
          key: 'sport_id',
          type: 'select',
          label: 'Sports',
          options: [{label:'All',value:''}, ...customOptions.sport] || sportOptions,
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.sport_id || ''
        },
        {
          key: 'start_date',
          type: 'date',
          label: 'Start Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.start_date || ''
        },
        {
          key: 'end_date',
          type: 'date',
          label: 'End Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.end_date || ''
        },
      ];

    case 'report-profitLoss':
      return [
        {
          key: 'start_date',
          type: 'date',
          label: 'Start Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.start_date || ''
        },
        {
          key: 'end_date',
          type: 'date',
          label: 'End Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.end_date || ''
        },
      ];

    case 'report-account-statement':
      return [
        {
          key: 'user_name',
          type: 'text',
          label: 'Search User',
          placeholder: 'Search By User Name',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.user_name || ''
        },
        {
          key: 'statement_type',
          type: 'select',
          label: 'Type',
          options: [
            { value: '0', label: 'All' },
            { value: '1', label: 'Free Chips' },
            { value: '2', label: 'Profit/Loss' },
            { value: '7', label: 'Request Lock Amount' },
            { value: '8', label: 'Bonus' },
            { value: '9', label: 'Referral' },
            { value: '10', label: 'Rewards' },
            { value: '16', label: 'Settlement' },
          ],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.statement_type || ''
        },
        {
          key: 'start_date',
          type: 'date',
          label: 'Start Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.date_range || today
        },
        {
          key: 'end_date',
          type: 'date',
          label: 'End Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.date_range || today
        },
        {
          key: 'user_id',
          type: 'text',
          label: 'User ID',
          validation: { required: false },
          visibility: false,
          defaultValue: defaultValues?.user_id || ''
        }
      ];

    case 'result-market':
      return [
        {
          key: 'start_date',
          type: 'date',
          label: 'Start Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.start_date || today
        },
        {
          key: 'is_active',
          type: 'select',
          label: 'Status',
          options: [
            { value: '', label: 'Select' },
            { value: '1', label: 'Active' },
            { value: '', label: 'Inactive' },
          ],
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.is_active || ''
        },
        {
          key: 'end_date',
          type: 'date',
          label: 'End Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.end_date || today
        },
      ];

    case 'result-fancy':
      return [
        {
          key: 'start_date',
          type: 'date',
          label: 'Start Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.start_date || today
        },
        {
          key: 'end_date',
          type: 'date',
          label: 'End Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.end_date || today
        },
       
      ];

    case 'result-rollback':
      return [
        {
          key: 'start_date',
          type: 'date',
          label: 'Start Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.start_date || today
        },
        {
          key: 'end_date',
          type: 'date',
          label: 'End Date',
          validation: { required: false },
          visibility: true,
          defaultValue: defaultValues?.end_date || today
        },
   
      ];

    default:
      return [];
  }
}
