import { createTheme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    layButtonColor: string;
    backButtonColor: string;
    buttonPrimaryColor: string;
    layButtonColorBg: string;
    backButtonColorBg: string;
    appBarColor: string;
    backgroundColor: string;
    bgColor: string;
    buttonSecondaryColor: string;
  }

  interface PaletteOptions {
    layButtonColor?: string;
    backButtonColor?: string;
    buttonPrimaryColor?: string;
    layButtonColorBg?: string;
    backButtonColorBg?: string;
    appBarColor?: string;
    backgroundColor?: string;
    bgColor?: string;
    buttonSecondaryColor?: string;
  }
}


const theme = createTheme({
  palette: {
    primary: {
      main: '#dbac34',
    },
    secondary: {
      main: '#FFFFFF', // Replace with your desired secondary color
    },
    layButtonColor: "#f9c9d4",
    backButtonColor: "#addbf8",
    buttonPrimaryColor: '#e1f038',
    layButtonColorBg: "rgb(239 193 179 / 93%)",
    backButtonColorBg: "rgb(179 209 239 / 93%)",
    appBarColor: "#242323",
    backgroundColor: '#E5E5E5',
    bgColor: '#212124',
    buttonSecondaryColor: '#9E172F',
  },
  typography: {
    fontFamily: [
      'Lato',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
    fontSize: 13,
    fontWeightRegular: 400,
  },
} as ThemeOptions);

export default theme;