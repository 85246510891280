import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  styled,
  CircularProgress,
  Toolbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ADD_ACCOUNT_CONFIG } from './config';
import { useApi } from '../../../hooks/useApi';
import { ADD_USER_URL } from '../../../utils/apiUrl';
import theme from '../../../utils/customTheme';
import { toast } from 'react-toastify';
import { useState } from 'react';
import React from 'react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.bgColor,
  color: 'white',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: theme.palette.primary.main,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
  padding: theme.spacing(1, 3),
  fontSize: "0.6 rem",
}));


const StyledGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const SectionDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

const ScrollableContent = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 64px)', // Adjust this value based on your toolbar height
  overflowY: 'auto',
  padding: theme.spacing(3),
}));


const formSections = [
  {
    title: 'User Information',
    fields: ['name', 'loginId', 'password', 'phoneNumber', 'creditLimit', 'role']
  },
  // {
  //   title: 'Partnership',
  //   fields: ['uplinePartnership', 'downlinePartnership', 'ourPartnership']
  // },
  {
    title: 'Sports Settings',
    fields: ['sports']
  },
  {
    title: 'Submit Details',
    fields: ['transactionPassword']
  }
];

const AddUser: React.FC = () => {
  const { isLoading, sendRequest } = useApi();
  const [role,setRole]=useState('')
  const initialValues = {
    name: '',
    loginId: '',
    password: '',
    phoneNumber: '',
    creditLimit: 0,
    role: '',
    // uplinePartnership: ADD_ACCOUNT_CONFIG.DEFAULT_VALUES.uplinePartnership,
    // downlinePartnership: ADD_ACCOUNT_CONFIG.DEFAULT_VALUES.downlinePartnership,
    transactionPassword: '',
    sports: [
      { id: '4', name: 'Cricket' },
      // { id: '1', name: 'Soccer' },
      // { id: '2', name: 'Tennis' },
    ].map(sport => ({
      partnership: role === '10' ? '0' : '85',
      sportId: sport.id,
      sportName: sport.name,
      betDelay: 3,
      sessionDelay: 2,
      bookmakerDelay: 2,
      oddsMinStack: 100,
      sessionMinStack: 100,
      bookmakerMinStack: 100,
      oddsMaxStack: 100000,
      bookmakerMaxStack: 100000,
      sessionMaxStack: 100000,
      commissionType: role === '10' ? '0' : '0',
      matchCommission: role === '10' ? 0 : 1,
      matchCommissionType: role === '10' ? 0 : 2,
      sessionCommission: role === '10' ? 0 : 1,
      sessionCommissionType: role === '10' ? 0 : 3,
      bookmakerCommission: role === '10' ? 0 : 2,
      bookmakerCommissionType: role === '10' ? 0 : 0,
    })),
  };
  console.log(initialValues)
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    loginId: Yup.string().required('Required'),
    password: Yup.string()
      .min(ADD_ACCOUNT_CONFIG.VALIDATION.PASSWORD_MIN_LENGTH, `Password must be at least ${ADD_ACCOUNT_CONFIG.VALIDATION.PASSWORD_MIN_LENGTH} characters`)
      .required('Required'),
    phoneNumber: Yup.string().matches(ADD_ACCOUNT_CONFIG.VALIDATION.PHONE_REGEX, 'Invalid phone number').required('Required'),
    creditLimit: Yup.number().min(0).required('Required'),
    role: Yup.string().required('Required'),
    transactionPassword: Yup.string().required('Required'),
    sports: Yup.array().of(
      Yup.object().shape({
        betDelay: Yup.number().integer().min(0).required('Required'),
        sessionDelay: Yup.number().integer().min(0).required('Required'),
        bookmakerDelay: Yup.number().integer().min(0).required('Required'),
        oddsMaxStack: Yup.number().integer().min(0).required('Required'),
        oddsMinStack: Yup.number().integer().min(0).required('Required'),
        sessionMaxStack: Yup.number().integer().min(0).required('Required'),
        bookmakerMaxStack: Yup.number().integer().min(0).required('Required'),
        sessionMinStack: Yup.number().integer().min(0).required('Required'),
        bookmakerMinStack: Yup.number().integer().min(0).required('Required'),
        partnership: Yup.number().when('role', {
          is: (role: string) => role !== '10',
          then: () => Yup.number().min(0).max(100).required('Required'),
          otherwise: () => Yup.number().strip(),
        }),
        commissionType: Yup.string().when('role', {
          is: (role: string) => role !== '10',
          then: () => Yup.string().oneOf(['0', '1', '2']).required('Required'),
          otherwise: () => Yup.string().strip(),
        }),
        matchCommission: Yup.number().when('role', {
          is: (role: string) => role !== '10',
          then: () => Yup.number().min(0).max(100).required('Required'),
          otherwise: () => Yup.number().strip(),
        }),
        sessionCommission: Yup.number().when('role', {
          is: (role: string) => role !== '10',
          then: () => Yup.number().min(0).max(100).required('Required'),
          otherwise: () => Yup.number().strip(),
        }),
        bookmakerCommission: Yup.number().when('role', {
          is: (role: string) => role !== '10',
          then: () => Yup.number().min(0).max(100).required('Required'),
          otherwise: () => Yup.number().strip(),
        }),
      })
    ),
  });
  
  const handleSubmit = async (values: typeof initialValues, { setSubmitting, resetForm }: any) => {
    try {
      const payload = {
        user_name: values.loginId,
        password: values.password,
        master_password: values.transactionPassword,
        name: values.name,
        mobile: values.phoneNumber,
        user_type_id: values.role,
        parent_id: 0,
        verify_otp: "123456",
        credit_limit: String(values.creditLimit),
        settings: values.sports.map(sport => {
          const isRole10 = values.role === '10';
          return {
            sport_id: sport.sportId,
            partnership: isRole10 ? '0' : sport.partnership,
            bet_delay: sport.betDelay,
            session_delay: sport.sessionDelay,
            bookmaker_delay: sport.bookmakerDelay,
            odds_max_stack: sport.oddsMaxStack,
            odds_min_stack: sport.oddsMinStack,
            session_max_stack: sport.sessionMaxStack,
            bookmaker_max_stack: sport.bookmakerMaxStack,
            session_min_stack: sport.sessionMinStack,
            bookmaker_min_stack: sport.bookmakerMinStack,
            match_commission: isRole10 ? 0 : sport.matchCommission,
            session_commission: isRole10 ? 0 : sport.sessionCommission,
            bookmaker_commission: isRole10 ? 0 : sport.bookmakerCommission,
          };
        }),
      };

      const response = await sendRequest(ADD_USER_URL, "POST", payload);

      if (response.status === 200 || response.status === 201) {
        toast.success(response.message, {
          position: 'top-center',
          autoClose: 5000
        });
        resetForm();
      } else {
        toast.error(response.message, {
          position: 'top-center',
          autoClose: 5000
        });
      }
    } catch (error: any) {
      console.error('Error submitting form:', error.message);
      toast.error('An error occurred. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const renderSportFields = (section: string, touched: any, errors: any, values: any) => {
    const sectionFields: Record<string, string[]> = {
      'Commission Settings': ['matchCommission', 'sessionCommission', 'bookmakerCommission'],
      'Partnership Settings': ['partnership'],
      'Min/Max Bet Settings': ['oddsMinStack', 'sessionMinStack', 'bookmakerMinStack', 'oddsMaxStack', 'sessionMaxStack', 'bookmakerMaxStack',],
      'Bet Delay Settings': ['betDelay', 'sessionDelay', 'bookmakerDelay'],
    };


    return values.sports.map((sport: any, sportIndex: number) => (
      <StyledAccordion key={`${section}-${sport.sportId}`}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{sport.sportName}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {sectionFields[section].map((key) => (
              <StyledGridItem item xs={12} sm={6} md={4} key={key}>
                <Field
                  as={TextField}
                  fullWidth
                  name={`sports[${sportIndex}].${key}`}
                  label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                  type={key.includes('Type') || key.includes('Delay') || key.includes('Stack') ? 'number' : 'text'}
                  error={touched.sports?.[sportIndex]?.[key] && !!errors.sports?.[sportIndex]?.[key]}
                  helperText={touched.sports?.[sportIndex]?.[key] && errors.sports?.[sportIndex]?.[key]}
                />
              </StyledGridItem>
            ))}
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    ));
  };

  const renderField = (fieldName: string, touched: any, errors: any, values: any, setFieldValue: any) => {
    if (fieldName === 'role') {
      return (
        <Field
          as={Select}
          fullWidth
          name={fieldName}
          label="Select Role"
          error={touched[fieldName] && !!errors[fieldName]}
          onChange={(e: any) => {
            setRole(e.target.value);
            setFieldValue(fieldName, e.target.value);
          }}
          value={values[fieldName]}
        >
          <MenuItem value="">Select Role</MenuItem>
          {ADD_ACCOUNT_CONFIG.ACCOUNT_TYPES.map((type) => (
            <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
          ))}
        </Field>
      );
    }

    if (fieldName === 'sports') {
      if(role==='10'){
        return <>
        <SectionTitle variant="h6">Min/Max Bet Settings</SectionTitle>
          {renderSportFields('Min/Max Bet Settings', touched, errors, values)}

          <SectionTitle variant="h6">Bet Delay Settings</SectionTitle>
          {renderSportFields('Bet Delay Settings', touched, errors, values)}
        </>
      }
      return (
        <>
          <SectionTitle variant="h6">Commission Settings</SectionTitle>
          {renderSportFields('Commission Settings', touched, errors, values)}

          <SectionTitle variant="h6">Partnership Settings</SectionTitle>
          {renderSportFields('Partnership Settings', touched, errors, values)}

          <SectionTitle variant="h6">Min/Max Bet Settings</SectionTitle>
          {renderSportFields('Min/Max Bet Settings', touched, errors, values)}

          <SectionTitle variant="h6">Bet Delay Settings</SectionTitle>
          {renderSportFields('Bet Delay Settings', touched, errors, values)}
        </>
      );
    }

    return (
      <Field
        as={TextField}
        fullWidth
        name={fieldName}
        label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1).replace(/([A-Z])/g, ' $1')}
        type={fieldName.includes('password') ? 'password' : fieldName.includes('Limit') || fieldName.includes('Bet') || fieldName.includes('Delay') ? 'number' : 'text'}
        error={touched[fieldName] && !!errors[fieldName]}
        helperText={touched[fieldName] && errors[fieldName]}
      />
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

      <ScrollableContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleSubmit, isSubmitting, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <StyledToolbar>
                <Typography variant="h4">Add Account</Typography>
                <StyledButton
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    background: theme.palette.buttonSecondaryColor,
                    color: "white",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Create User'}
                </StyledButton>
              </StyledToolbar>
              <StyledPaper elevation={3}>
                {formSections.map((section, index) => (
                  <React.Fragment key={index}>
                    <SectionHeader variant="h6">{section.title}</SectionHeader>
                    {section.title !== 'Sports Settings' ? (
                      <StyledGrid container spacing={3}>
                        {section.fields.map((fieldName) => (
                          <StyledGridItem item xs={12} sm={6} md={4} key={fieldName}>
                            {renderField(fieldName, touched, errors, values, setFieldValue)}
                          </StyledGridItem>
                        ))}
                      </StyledGrid>
                    ) : (
                      renderField('sports', touched, errors, values, setFieldValue)
                    )}
                  </React.Fragment>
                ))}
              </StyledPaper>
            </Form>
          )}
        </Formik>
      </ScrollableContent>
    </Box>
  );
};

export default AddUser;