import React, { useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { navMenuList } from "../../constants/navMenuList";
import Clock from "../Clock";
import { useWebSocketSelector } from "../../hooks/useWebSocket";
// Add these imports
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Icon } from "@mui/material";

const Backdrop = styled("div")<{ open: boolean }>(({ open }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1,
  display: open ? "block" : "none",
}));

interface SideBarLeftProps {
  toggled?: boolean;
  onClose: () => void; // Add this line
  [key: string]: any;
}

const SidebarContainer = styled("div")<{ toggled: boolean }>(
  ({ theme, toggled }) => ({
    position: "fixed",
    height: "100%",
    zIndex: 2,
    fontFamily: '"Lato", "Helvetica Neue", sans-serif',
    transition: "width 0.3s ease",
    width: toggled ? "65vw" : 0,
    background: `linear-gradient(135deg, ${theme.palette.bgColor} 0%, ${theme.palette.bgColor}CC 100%)`,
    backdropFilter: "blur(10px)",
    WebkitBackdropFilter: "blur(10px)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
    border: "1px solid rgba(255, 255, 255, 0.18)",
    [theme.breakpoints.up("md")]: {
      width: toggled ? "65vw" : "17vw",
    },
  })
);

const SidebarInner = styled("div")<{ toggled: boolean }>(
  ({ theme, toggled }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      display: toggled ? "flex" : "none",
    },
  })
);

const SidebarContent = styled("div")({
  flexGrow: 1,
  overflowY: "auto",
  padding: "10px",
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    outline: "1px solid slategrey",
  },
});

const ClockContainer = styled("div")({
  padding: "10px",
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
});

const SidebarMenu = styled("nav")({
  color: "white",
});

const MenuItem = styled("div")(({ theme }) => ({
  padding: "0.75rem 1rem",
  cursor: "pointer",
  fontWeight: 400,
  fontSize: "1rem",
  "&:hover": {
    fontWeight: 500,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  "&.top-level": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const SubMenuTitle = styled("div")({
  padding: "0.75rem 1rem",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const SubMenuContent = styled("div")({
  paddingLeft: "1rem",
});

function SideBarLeft({
  toggled = false,
  onClose,
  ...restProps
}: SideBarLeftProps): JSX.Element {
  const navigate = useNavigate();
  const [openSubMenus, setOpenSubMenus] = useState<string[]>([]);
  const { marketIds } = useWebSocketSelector((state) => state.websocket);
  const isUser =
    JSON.parse(sessionStorage.getItem("adminUserDetails") || "{}")
      ?.user_type_id === 10
      ? true
      : false;

  const handleNavigation = (path: string) => {
    if (path !== "#") {
      navigate(path);
    }
  };

  const toggleSubMenu = (id: string) => {
    setOpenSubMenus((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const groupedMenuArray = useMemo(() => {
    return _.chain(marketIds)
      .filter((market) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const matchDate = new Date(market.date);
        matchDate.setHours(0, 0, 0, 0);
        return matchDate >= today;
      })
      .groupBy("sport_name")
      .map((data, key) => ({
        gameType: key,
        totalMatches: _.uniqBy(data, "match_id").length,
        seriesNames: _.chain(data)
          .uniqBy("match_id")
          .groupBy("series_name")
          .map((seriesData, seriesKey) => ({
            seriesName: seriesKey,
            allMatches: seriesData,
          }))
          .value(),
      }))
      .value();
  }, [marketIds]);

  const renderMenuItems = useMemo(() => {
    return groupedMenuArray.map((el) => (
      <>
        <SubMenuTitle onClick={() => toggleSubMenu(el.gameType)}>
          {el.gameType}
          {openSubMenus.includes(el.gameType) ? (
            <ExpandMoreIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </SubMenuTitle>
        {openSubMenus.includes(el.gameType) && (
          <SubMenuContent>
            {el.seriesNames.map((elSeries) => (
              <MenuItem
                key={elSeries.seriesName}
                className="submenu"
                sx={{ fontSize: "12px" }}
              >
                <SubMenuTitle
                  onClick={() => toggleSubMenu(elSeries.seriesName)}
                >
                  {elSeries.seriesName}
                  {openSubMenus.includes(elSeries.seriesName) ? (
                    <ExpandMoreIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </SubMenuTitle>
                {openSubMenus.includes(elSeries.seriesName) && (
                  <SubMenuContent>
                    {elSeries.allMatches.map((elMatch) => (
                      <MenuItem
                        key={elMatch.match_id}
                        onClick={() =>
                          handleNavigation(
                            `/sports/${elMatch.sport_name?.toLowerCase()}/${
                              elMatch.market_id
                            }/${elMatch.match_id}`
                          )
                        }
                        sx={{ fontSize: "12px" }}
                      >
                        {elMatch.match_name}
                      </MenuItem>
                    ))}
                  </SubMenuContent>
                )}
              </MenuItem>
            ))}
          </SubMenuContent>
        )}
      </>
    ));
  }, [groupedMenuArray, openSubMenus, handleNavigation]);

  return (
    <>
      <Backdrop open={toggled} onClick={onClose} />
      
      <SidebarContainer toggled={toggled} {...restProps}>
      <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                width: "100%",
            
              }}
            >
              <Clock />
            </div>
        <SidebarInner toggled={toggled}>

       
          <SidebarContent>
       

            <SidebarMenu>
              {!isUser &&
                navMenuList.map((el) => {
                  if (el.hasOwnProperty("subMenu")) {
                    return (
                      <>
                        <SubMenuTitle
                          onClick={() => toggleSubMenu(el.id.toString())}
                        >
                          <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
                          {el.icon && <Icon component={el.icon} fontSize="small" />}
                          {el.name}
                          </div>
                          
                          {openSubMenus.includes(el.id.toString()) ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ChevronRightIcon />
                          )}
                        </SubMenuTitle>
                        {openSubMenus.includes(el.id.toString()) && (
                          <SubMenuContent>
                            {el.subMenu?.map((subEl) => (
                              <MenuItem
                                key={subEl.id}
                                onClick={() => handleNavigation(subEl.path)}
                              >
                                {subEl.name}
                              </MenuItem>
                            ))}
                          </SubMenuContent>
                        )}
                      </>
                    );
                  } else {
                    return (
                      <MenuItem
                        key={el.id}
                        onClick={() => handleNavigation(el.path)}
                        className="submenu"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "10px",
                        }}
                      >
                        {el.icon && <Icon component={el.icon} fontSize="small" />}
                        {el.name}
                      </MenuItem>
                    );
                  }
                })}

              {renderMenuItems}
            </SidebarMenu>
          </SidebarContent>
        </SidebarInner>
      </SidebarContainer>
    </>
  );
}

export default React.memo(SideBarLeft);
